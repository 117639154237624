/*jslint white: true, nomen: true */
/*global angular, google */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeSitePlanCtrl', ['$scope', '$compile', '$templateCache',
      function($scope, $compile, $templateCache) {

        var currencyFormatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0
        });
        var numberFormatter = new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 0
        });

        $scope.gm = null;
        $scope.map = null;
        $scope.markers = [];
        $scope.isMapLoaded = false;
        $scope.isMapDataLoaded = false;

        $scope.communityPromise
          .then(function(community) {
            angular.forEach(community.lot_maps, function(lotMap, key) {
              angular.forEach(lotMap.lots, function(lot, key) {
                lot.pin = '/images/site/pins/' + lot.status + '-pin.png';
              });
            });

            $scope.isMapDataLoaded = true;
            if ($scope.isMapLoaded) {
              assignLotMapTiles(community.lot_maps[0]);
              setLots(community.lot_maps[0].lots);
            }
          })
        ;

        var generateInfoWindowContent = (lot) => {
          var wrapper = document.createElement('div');

          wrapper.className = 'popup-wrapper';

          // Create a new isolated scope for this info window
          var newScope = $scope.$new(true);

          // Set the parameter on the new scope (this matches the template's expected parameter)
          newScope.parameter = lot;

          // Get the template from the template cache
          var template = $templateCache.get('map/communityWindow');

          // Compile the template with the new scope
          var compiledTemplate = $compile(template)(newScope);

          // Instead of forcing a digest, use $evalAsync to schedule the digest if needed
          if (!$scope.$$phase) {
            newScope.$evalAsync();
          }

          // Return the compiled DOM element
          wrapper.appendChild(compiledTemplate[0]);

          return wrapper;
        };

        var assignLotMapTiles = (lotMap) => {
          var TILE_URL = lotMap.tiles_path;
          var layerID = 'my_custom_layer';
          var layer = new google.maps.ImageMapType({
            name: layerID,
            getTileUrl: function (coord, zoom) {
              var url = TILE_URL
                .replace('{x}', coord.x)
                .replace('{y}', coord.y)
                .replace('{z}', zoom);
              return url;
            },
            tileSize: new google.maps.Size(256, 256),
            minZoom: 1,
            maxZoom: 20
          });

          $scope.map = new google.maps.Map(document.getElementById('site-plan-map'), {
            mapId: 'fec828edcb8e95f3',
            center: new google.maps.LatLng(0, 0),
            zoom: 2,
            clickableIcons: false,
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.TOP_LEFT
            },
          });

          $scope.map.overlayMapTypes.push(layer);
          $scope.infoWindow = new google.maps.InfoWindow();
        };

        var setLots = (lots) => {
          $scope.markers = [];
          lots.forEach((lot) => {
            var lat = lot.latitude && !isNaN(lot.latitude) ? parseFloat(lot.latitude) : 0;
            var lng = lot.longitude && !isNaN(lot.longitude) ? parseFloat(lot.longitude) : 0;

            if (lat === 0 || lng === 0) {
              return;
            }

            var infoWindowContent = generateInfoWindowContent(lot);
            var unclickableStatuses = ['reserved', 'sold', 'non-urban-nw-homesite'];

            var markerContent = document.createElement('div');
            var markerImage = document.createElement('img');
            markerImage.src = lot.pin;
            markerImage.alt = lot.status;
            markerContent.appendChild(markerImage);

            var marker = new google.maps.marker.AdvancedMarkerElement({
              map: $scope.map,
              position: new google.maps.LatLng(lat, lng),
              content: markerContent,
              gmpClickable: !unclickableStatuses.includes(lot.status)
            });

            marker.addListener('click', () => {
              var unclickableStatuses = ['reserved', 'sold', 'non-urban-nw-homesite'];
              if (unclickableStatuses.includes(lot.status)) {
                return;
              }

              $scope.infoWindow.setContent(infoWindowContent);
              $scope.infoWindow.open($scope.map, marker);
            });
            $scope.markers.push(marker);
          });
        };

        Promise.all([
          google.maps.importLibrary('marker'),
          google.maps.importLibrary('maps')
        ]).then(() => {
          $scope.isMapLoaded = true;
          if ($scope.isMapDataLoaded) {
            assignLotMapTiles($scope.community.lot_maps[0]);
            setLots($scope.community.lot_maps[0].lots);
          }
        });

    }]);
}());
