/*jslint white: true, nomen: true */
/*global angular, google */
(function() {
  'use strict';

  angular.module('app.findYourHome')
    .controller('FindYourHomeCtrl', ['$scope', '$timeout', 'DataService', 'DeviceService',
      function($scope, $timeout, DataService, DeviceService) {

        // range slider setup
        $scope.priceRange = {
          min: 0,
          max: 500
        };

        $scope.communities = [];
        $scope.filteredCommunities = [];
        $scope.map = null;
        $scope.isMapApiReady = false;
        $scope.isMapDataLoaded = false;
        $scope.infoWindow = null;
        $scope.markers = [];
        $scope.selectedCommunity = null;

        var specAggregates = {
          property_type: [],
          price: [],
          bed: [],
          bath: [],
          sqft: []
        };

        var loadCommunities = function() {
          return DataService('/api/community', 'communities')
            .promise
            .then(function(communities) {
              $scope.communities = communities;
              angular.forEach(communities, function(community) {
                if (specAggregates.property_type.indexOf(community.property_type) === -1) {
                  specAggregates.property_type.push(community.property_type);
                }
                specAggregates.price.push(community.price);
                specAggregates.bed.push(community.bed_low);
                specAggregates.bath.push(community.bath_low);
                specAggregates.sqft.push(community.sqft_low);
                specAggregates.sqft.push(community.sqft_high);
              });

              $scope.isMapDataLoaded = true;
              if ($scope.isMapApiReady) {
                setupMap();
              }

              return $scope.communities;
            });
        };

        loadCommunities()
          .then(function(community_markers) {
            $scope.loading = false;
          });

          function fitBoundsWithCurrentMarkers() {
            if (!$scope.map) {
              return;
            }

            var newBounds = new google.maps.LatLngBounds();
            $scope.markers.forEach(function(marker) {
              newBounds.extend(marker.position);
            });

            newBounds.extend(new google.maps.LatLng(
              $scope.map.getBounds().getNorthEast().lat() + 0.01,
              $scope.map.getBounds().getNorthEast().lng() + 0.01
            ));
            newBounds.extend(new google.maps.LatLng(
              $scope.map.getBounds().getSouthWest().lat() - 0.01,
              $scope.map.getBounds().getSouthWest().lng() - 0.01
            ));

            $scope.map.fitBounds(newBounds);
          }
          function setupMarkers() {
            $scope.communities.forEach(function(community) {
              console.log(community);
              var lat = isNaN(community.latitude) ? 0 : community.latitude;
              var lng = isNaN(community.longitude) ? 0 : community.longitude;

              if (lat === 0 || lng === 0) {
                return;
              }

              var markerContent = document.createElement('div');
              var markerImage = document.createElement('img');
              markerImage.src = '/images/site/fa-map-marker.png';
              markerImage.alt = community.name;
              markerImage.width = 30;
              markerImage.height = 33;
              markerContent.id = 'marker-' + community.id;
              markerContent.appendChild(markerImage);

              // get angular template from findYourHomeWindowTemplate.blade.php
              var infoWindowContent = document.createElement('div');
              infoWindowContent.classList.add('popup-wrapper');
              var infoWindowImage = community.carousel_images[0]
                ? community.carousel_images[0].image.medium : '';
              if (!infoWindowImage && community.assets[0]) {
                infoWindowImage = community.assets[0].image.medium;
              }
              var infoWindowImageHtml = infoWindowImage ? '<div class="left-thumb pull-left" style="background-image:url(' + infoWindowImage + ');"></div>' : '';
              infoWindowContent.innerHTML = '<div class="unw-popup map-pin-popup"><a class="popup" ng-href="/' + community.seo_name + '">' + infoWindowImageHtml + '<div class="unw-popup-description pull-left"><div class="top-section"><h2>' + community.name + '</h2><p class="location">' + community.city + ', ' + community.state + ' <span class="divide">|</span> ' + community.school_district + '</p><p>' + community.property_type + ' ' + community.price_status + '</p></div><span class="community-cta">Visit Community</span></div></a></div>';

              var marker = new google.maps.marker.AdvancedMarkerElement({
                map: $scope.map,
                position: new google.maps.LatLng(lat, lng),
                content: markerContent,
                gmpClickable: true,
              });

              marker.addListener('click', function() {
                $scope.infoWindow.setContent(infoWindowContent);
                $scope.infoWindow.open($scope.map, marker);
              });

              $scope.markers.push(marker);
            });

            $timeout(function() {
              fitBoundsWithCurrentMarkers();
            }, 300);
          }

          function setupMap() {
            var mapElem = document.getElementById('find-your-home-map');

            $scope.map = new google.maps.Map(mapElem, {
              mapId: '79de09f45f7295d3',
              center: new google.maps.LatLng(45.638023, -122.663495),
              zoom: 10,
              clickableIcons: false,
              disableDefaultUI: true,
              zoomControl: true,
              zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
              }
            });

            $scope.infoWindow = new google.maps.InfoWindow({ });

            setupMarkers();
          }

          Promise.all([
            google.maps.importLibrary('maps'),
            google.maps.importLibrary('marker'),
          ]).then(() => {
            $scope.isMapApiReady = true;
            if ($scope.isMapDataLoaded) {
              setupMap();
            }
          });

      }]);
}());