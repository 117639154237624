(function() {
  "use strict";

  angular
    .module('app.microsite', [
      'ngRoute',
      'ngSanitize',
      "angular-inview",
      "ui.bootstrap",
      'ngAnimate',
      'duScroll',
      'app.forms',
      'app.directives',
      "app.common",
      'app.mainNav',
      'slickCarousel',
      "modules.dataServices",
      "modules.priceStartingAt",
      "modules.snapshotRange",
      "modules.laravelParameters",
      "modules.trustHtml",
      "modules.trustAsResourceUrl",
      "modules.device",
    ])
    .config(['$interpolateProvider', function($interpolateProvider) {
      $interpolateProvider.startSymbol('<%');
      $interpolateProvider.endSymbol('%>');
    }])
    .config(['$routeProvider', '$locationProvider',
      function($routeProvider, $locationProvider) {
        $locationProvider.html5Mode(true);

        $routeProvider
          .when('/:page', {
            controller: [function () {
              console.log('whats the difference here:');
            }]
          })
          .otherwise({
            template: "<div></div>",
            controller: ['$window', '$location', '$rootScope',
              function($window, $location, $rootScope) {
                // Commenting this out seems to fix the url back button issues
                // If we find this is actually still necessary, we may find we should use
                // `window.history.replaceState()` to prevent a reload.
                // $window.location.href = $location.absUrl();
                console.log('Logging here for reference', $window.history);
              }
            ]
          })
        ;
      }
    ])
    .service('MicrositeScrollAnimationQueue', [function() {
      var data = {
        animation: false
      };

      return data;
    }])
    .run(['$rootScope', '$location', '$anchorScroll', '$routeParams', '$timeout', 'MicrositeScrollAnimationQueue', '$window',
      function($rootScope, $location, $anchorScroll, $routeParams, $timeout, MicrositeScrollAnimationQueue, $window) {

        var scrollToUrl = function() {

          var hash = $location.path().replace(/^(\/)/, '#');


          var needtoScroll;

          // if (!hash) {
          //   $('html, body').stop(true).animate({
          //     scrollTop: needtoScroll
          //   }, 800, 'easeOutQuart');
          // }

          var a = $(hash);
          var offset = 125;

          if ($("li").hasClass(".offset-item")) {
            var offset = 90;
          }


          if (a.length) {

            if (a.data !== undefined) {
              if (a.data().offset !== undefined) {
                offset = a.data().offset;
              }
            }

            if ($('#sub-nav .snapshot-info:hidden').length > 0) {
              offset = offset + 350;
            }

            needtoScroll = a.offset().top - offset;

            $('html, body').stop(true).animate({
              scrollTop: needtoScroll
            }, 800, 'easeOutQuart');

          } else {

            $anchorScroll(hash);

          }

        };

        // Manuall scrolling stops any animations
        $('html, body').bind('scroll mousedown wheel DOMMouseScroll mousewheel keyup', function(e){
          $("html, body").stop(true);
        });

        $rootScope.$on('$routeChangeStart', function() {
          $rootScope.isRouteLoading = true;
        });

        $rootScope.$on('$locationChangeStart', function() {
          $rootScope.isRouteLoading = true;
        });

        //when the route is changed scroll to the proper element.
        $rootScope.$on('$routeChangeSuccess', function(newRoute, oldRoute) {
          //scrollToUrl();
          //console.log("reoute ahs changed");
        });

        $rootScope.$on('$locationChangeSuccess', function() {
          scrollToUrl();
        });

        //$rootScope.scrollToUrl = scrollToUrl;

        //location replace on scroll
        $rootScope.$on('scrollspyTriggered', function(event, args) {
          //console.log("ARGS: " + args.elemTarget);

          $location.path("/" + args.elemTarget, true);
          $rootScope.$apply();

        });


      }
    ]);

  //   .directive('galleryModal', [
  //     function(){
  //       return {
  //         templateUrl: "galleryModalTemplate.html",
  //         scope: {
  //           "gallery": "="
  //         },
  //         link: {

  //         }
  //       };
  //     }
  //   ])
  // ;



})();
