/*jslint white: true, nomen: true */
/*global angular, google */
(function() {
  'use strict';

  angular.module('app.whereWeBuild')
    .controller('whereWeBuildCtrl', ['$scope', '$timeout', 'DataService', 'FormService', 'TrackingService', '$templateCache', '$compile',
      function($scope, $timeout, DataService, FormService, TrackingService, $templateCache, $compile) {

      $scope.availableAcreages = [];
      $scope.map = null;
      $scope.isMapReady = false;
      $scope.isMapDataLoaded = false;
      $scope.markers = [];

      var loadAvailableAcreages = function() {
        return DataService('/api/available-acreage', 'availableAcreages')
          .promise
          .then(function(availableAcreages) {
            $scope.availableAcreages = availableAcreages;
            $scope.isMapDataLoaded = true;

            if ($scope.isMapReady) {
              setupMap();
            }

            return $scope.availableAcreages;
          });
      };

      function fitBoundsWithCurrentMarkers() {
        if (!$scope.map) {
          return;
        }

        var newBounds = new google.maps.LatLngBounds();
        $scope.markers.forEach(function(marker) {
          newBounds.extend(marker.position);
        });

        newBounds.extend(new google.maps.LatLng(
          $scope.map.getBounds().getNorthEast().lat() + 0.01,
          $scope.map.getBounds().getNorthEast().lng() + 0.01
        ));
        newBounds.extend(new google.maps.LatLng(
          $scope.map.getBounds().getSouthWest().lat() - 0.01,
          $scope.map.getBounds().getSouthWest().lng() - 0.01
        ));

        $scope.map.fitBounds(newBounds);
      }

      function generateInfoWindowContent(acreage) {
          var wrapper = document.createElement('div');
          wrapper.className = 'popup-wrapper';

          // Create a new isolated scope for this info window
          var newScope = $scope.$new(true);

          // Set the parameter on the new scope (this matches the template's expected parameter)
          newScope.parameter = acreage;

          // Get the template from the template cache
          var template = $templateCache.get('availableAcreageWindow.html');

          // Compile the template with the new scope
          var compiledTemplate = $compile(template)(newScope);

          // Instead of forcing a digest, use $evalAsync to schedule the digest if needed
          if (!$scope.$$phase) {
            newScope.$evalAsync();
          }

          // Return the compiled DOM element
          wrapper.appendChild(compiledTemplate[0]);

          return wrapper;
      }

      function setupMarkers() {
        $scope.markers = [];

        $scope.availableAcreages.forEach(acreage => {
          var lat = acreage.latitude && !isNaN(acreage.latitude) ? parseFloat(acreage.latitude) : 0;
          var lng = acreage.longitude && !isNaN(acreage.longitude) ? parseFloat(acreage.longitude) : 0;

          if (lat === 0 || lng === 0 || !acreage.availability) {
            return;
          }

          var markerContent = document.createElement('div');
          var markerImage = document.createElement('img');
          var infoWindowContent = generateInfoWindowContent(acreage);

          markerImage.src = '/images/site/'+acreage.availability+'-marker.png';
          markerImage.alt = acreage.title;
          markerImage.width = 30;
          markerImage.height = 33;
          markerContent.appendChild(markerImage);

          var marker = new google.maps.marker.AdvancedMarkerElement({
            position: new google.maps.LatLng(lat, lng),
            map: $scope.map,
            content: markerContent
          });

          marker.addListener('click', function() {
            $scope.infoWindow.setContent(infoWindowContent);
            $scope.infoWindow.open($scope.map, marker);
          });

          $scope.markers.push(marker);
        });

        $timeout(function() {
          fitBoundsWithCurrentMarkers();
        }, 300);
      }

      function setupMap() {
        $scope.map = new google.maps.Map(document.getElementById('where-we-build-map'), {
          mapId: '79de09f45f7295d3',
          center: new google.maps.LatLng(45.638023, -122.663495),
          zoom: 10,
          minZoom: 2,
          maxZoom: 15,
          clickableIcons: false,
          disableDefaultUI: true,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          }
        });

        $scope.infoWindow = new google.maps.InfoWindow({ });

        setupMarkers();
      }

      loadAvailableAcreages();


      /*====================================
      =            Declarations            =
      ====================================*/

      var self = this,
        prefillMode = false;

      self.formData = FormService.prefillForm(prefillMode, 'availableAcreage').formData;
      self.formResult = FormService.prefillForm(prefillMode, 'availableAcreage').formResult;

      if (typeof self.trackingName === 'undefined') {
        self.trackingName = 'availableAcreageForm';
      }

      self.checkFormValidation = function(form) {
        self.triedSubmit = true;
        if (! form.$valid) {
          return;
        }

        processAvailableAcreageForm();
      };

      function processAvailableAcreageForm() {
        self.formResult.showForm = false;
        self.formResult.showLoader = true;

        // mapping form fields to sync with zapier notification
        self.formData.phone = self.formData.phone_primary;
        self.formData.message = 'what are you interested in? ' + self.formData.interested_in + ' ' + self.formData.notes;

        FormService.submit('/forms/log-only', self.formData)
          .then(function(result) {
            self.formResult = result;
            if (result.status === 'success') {
              console.log('tracking: ', 'availableAcreageForm');
              TrackingService.logTrackingClick('availableAcreageForm', result);

              $rootScope.loadPseudoConfirmation();
            }
          });
      }

      Promise.all([
        google.maps.importLibrary('marker'),
        google.maps.importLibrary('maps'),
      ]).then(() => {
        $scope.isMapReady = true;
        if ($scope.isMapDataLoaded) {
          setupMap();
        }
      });

    }]);
}());