/*jslint white: true, nomen: true */
/*global angular, google */
(function() {
  'use strict';

  angular.module('app.moveInReadyDetail')
    .controller('MoveInReadyDetailCtrl', ['$scope', 'DataService', 'laravelParameters', '$timeout', '$uibModal', 'DeviceService', '$templateCache', '$compile',
      function($scope, DataService, laravelParameters, $timeout, $uibModal, DeviceService, $templateCache, $compile) {

        $scope.map = null;
        $scope.markers = [];
        $scope.isMapReady = false;
        $scope.isMapDataLoaded = false;
        $scope.infoWindow = null;

   DataService('/' + laravelParameters.segment(0)[0]).promise.then(function(result) {
        $scope.home = result.home;
        $scope.otherPlans = result.other_plans;
        $scope.selectTag('');
        $scope.isMapDataLoaded = true;

        if ($scope.isMapReady) {
          setupMap();
        }
    });

        $scope.scrollToFormMoreAboutThisPlan = function(event) {
          $('.form-more-about-this-plan').velocity('scroll', {
            duration: 1500,
            offset: -75,
            delay: 0,
            easing: 'ease-in-out'
          });
        }

        // small hack:
        //    the affix position changes from relative (affix) to fixed (affix-top), causes the page offset change about 64px (height of #sub-nav-revision element)
        var affix_offset = (DeviceService.check() == 'phone') ? 58 : -64;

        $('#sub-nav-revision').affix({
          offset: {
            top: function () {
              return (this.top = $('header.menu').outerHeight(true) + $('.sub-header-revision').outerHeight(true) - 111)
            }
          }
        })
        .on('affix.bs.affix', function() {
          affix_offset = 0;
        })
        .on('affix-top.bs.affix', function() {
          affix_offset = (DeviceService.check() == 'phone') ? -58 : -64;
        });
        /*
        .on('affix-top.bs.affix', function() {
          $('header.menu.fixed').removeClass('push-it-up');
        });
        */
        var controller = new ScrollMagic.Controller();

        var scene = new ScrollMagic.Scene({
          triggerElement: '#sub-nav-revision',
          triggerHook: 'onEnter onLeave',
          offset: 345
        })
        .addTo(controller)
        .on('enter', function (e) {
          $('header.menu.fixed').addClass('push-it-up');
          console.log('trap');
        })
        .on('leave', function(e) {
          $('header.menu.fixed').removeClass('push-it-up');
        });

        $('#sub-navigation-wrapper').on('click', 'a', function(e) {
          // 141 = height of main header
          // 64 = height of sub nav
          var target = $(this).attr('href').substr(1),
              target_offset_top = $('#'+target).offset().top + affix_offset + 3;

          $('html, body').stop(true).animate({
            scrollTop: target_offset_top
          }, 800, 'easeOutQuart');
        });



        $scope.selectElevation = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.elevationModal(gallery);
        }

        $scope.elevationModal = function(gallery) {
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'elevationModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };



        var numOfTiles = (DeviceService.check() == 'phone') ? 12 : 18;
        $scope.galleryLimitTo = numOfTiles;
        //console.log($scope.floorplan.assets.length);
        $scope.loadMoreImages = function() {
          $scope.galleryLimitTo += numOfTiles;
        };



        $scope.subActive = 0;

        $scope.selectedTag = "";

        $scope.selectImageAtIndex = function($index) {
          $scope.selectedIndex = $index;
        };

        $scope.selectImage = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.galleryModal(gallery);
        }

        $scope.galleryModal = function(gallery) {
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'galleryModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

        $scope.slickConfig = {
          enabled: false,
          dots: false,
          autoplay: false,
          fade: true,
          // speed: 1800,
          initialSlide: 0,
          infinite: true,
          autoplaySpeed: 10000,
          easing: "easeInElastic",
          useCSS: true,
          method: {},
          prevArrow: '<a class="left" href="#"><img src="/images/site/arrow-left.png" class="icon-prev"><div class="hover-ellipse"></div></a>',
          nextArrow : '<a class="right" href="#"><img src="/images/site/arrow-right.png" class="icon-next"><div class="hover-ellipse"></div></a>',
          event: {
            init: function(event, slick) {
              slick.slickGoTo(0); // slide to correct index when init
            },
            beforeChange: function(event, slick, currentSlide, nextSlide) {
              // return slideAnimation();
            }
          }
        };

      $scope.$watch('home.assets', function(is){
      	if (is === undefined) {
      		return;
      	}

        $timeout(function() {
          $scope.slickConfig.enabled = true;
        }, 1000);
      })

      $scope.selectTag = function(tag) {
        $scope.slickConfig.enabled = false;

        $scope.selectedTag = tag;

        $timeout(function() {
          $scope.slickConfig.enabled = true;
        });
      };

      //$scope.selectTag('');

      var matchTag = function(tag, tags) {
        // No selection means everything okay
        if (!tag) {
          return true;
        }

        if (tags !== undefined) {
          for (var i = tags.length - 1; i >= 0; i--) {
            if (tags[i].name === tag) {
              return true;
            }
          }
        }

        return false;
      };

      $scope.galleryFilter = function(image) {
        return matchTag($scope.selectedTag, image.tags);
      };

      function slideAnimation() {

        return $('.transition').velocity({
          height: '165%'
        }, {
          duration: 700,
          complete: function() {
            $(this).velocity({
              opacity: 0
            }, {
              duration: 500,
              complete: function() {
                $('.item .carousel-caption').find('img').css('opacity', 0);
                $(this).css({
                  bottom: -50,
                  height: '0%'
                }).velocity({
                  bottom: 0,
                  opacity: 1
                }, {
                  duration: 300
                });
              }
            });
          }
        });
      }

      // modal

      $scope.openGetUpdatesModal = function() {
  		  var modalInstance = $uibModal.open({
  		    templateUrl: 'moveInReadyGetUpdatesModal.html',
  		    controller: 'moveInReadyGetUpdatesModalCtrl',
  		    windowClass: 'modal-auto-size',
  		    animation: true,
  		    size: 'md',
  		    scope: $scope
  		  });
		};

      $scope.openCalendlyModal = function() {
        $scope.calendlyUrl = $scope.home.calendly_url;
        var modalInstance = $uibModal.open({
          templateUrl: 'calendlyModal.html',
          controller: 'calendlyModalCtrl',
          windowClass: 'modal-auto-size',
          animation: true,
          size: 'lg',
          scope: $scope
        });
    };

    function generateInfoWindow(home) {
      var wrapper = document.createElement('div');``
          wrapper.className = 'popup-wrapper';

          // Create a new isolated scope for this info window
          var newScope = $scope.$new(true);

          // Set the parameter on the new scope (this matches the template's expected parameter)
          newScope.parameter = $scope.home;

          // Get the template from the template cache
          var template = $templateCache.get('homeLocationWindow.html');

          // Compile the template with the new scope
          var compiledTemplate = $compile(template)(newScope);

          // Instead of forcing a digest, use $evalAsync to schedule the digest if needed
          if (!$scope.$$phase) {
            newScope.$evalAsync();
          }

          // Return the compiled DOM element
          wrapper.appendChild(compiledTemplate[0]);

          return wrapper;
    }

    function setupMarker() {
      var lat = $scope.home.latitude && !isNaN($scope.home.latitude) ? parseFloat($scope.home.latitude) : 0;
      var lng = $scope.home.longitude && !isNaN($scope.home.longitude) ? parseFloat($scope.home.longitude) : 0;

      if (!lat || !lng) {
        return;
      }

      var infoWindowContent = generateInfoWindow($scope.home);
      var markerContent = document.createElement('div');
      var markerImage = document.createElement('img');
      markerImage.src = '/images/site/fa-map-marker.png';
      markerImage.width = 30;
      markerImage.height = 33;
      markerContent.appendChild(markerImage);

      $scope.marker = new google.maps.marker.AdvancedMarkerElement({
        map: $scope.map,
        position: new google.maps.LatLng(lat, lng),
        content: markerContent,
        gmpClickable: true,
      });

      $scope.marker.addListener('click', function() {
        $scope.infoWindow.setContent(infoWindowContent);
        $scope.infoWindow.open($scope.map, $scope.marker);
      });
    }

    function setupMap() {
      var lat = $scope.home.latitude && !isNaN($scope.home.latitude) ? parseFloat($scope.home.latitude) : 45.679395;
      var lng = $scope.home.longitude && !isNaN($scope.home.longitude) ? parseFloat($scope.home.longitude) : -122.597837;

      $scope.map = new google.maps.Map(document.getElementById('homeLocationMap'), {
        mapId: '79de09f45f7295d3',
        center: new google.maps.LatLng(lat, lng),
        zoom: 14,
        clickableIcons: false,
        disableDefaultUI: true,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        }
      });

      $scope.infoWindow = new google.maps.InfoWindow({ });

      setupMarker();
    }

    Promise.all([
      google.maps.importLibrary('marker'),
      google.maps.importLibrary('maps')
    ]).then(function() {
      $scope.isMapReady = true;
      if ($scope.isMapDataLoaded) {
        setupMap();
      }
    });


    }]);
}());