(function() {

  'use strict';

  angular.module('app')

    .controller('WhereWeBuildMenuCtrl', ['$scope', 'DataService',
      function($scope, DataService) {

        $scope.communities = DataService('/api/community', 'communities').records;

      }
    ])
  ;
})();