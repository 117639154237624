// (function() {

  'use strict';
  /* global $, angular */

  angular
    .module("app.common")

    .directive('scrollspy', ['$location', '$document','$rootScope',
      function($location, $document, $rootScope) {

      return function(scope, elem, attrs) {

          var controller = new ScrollMagic.Controller();

          var triggerElem = attrs.id;
          var subnavHeight = -140;
          var e = document.getElementById(triggerElem);

          if (!e) {
            return;
          }

          //microsite scrollspy broadcast on rootscope
          if($('body').hasClass('microsite')){

            var sceneTwo = new ScrollMagic.Scene({
                  triggerElement: "#" + triggerElem,
                  offset: subnavHeight,
                    triggerHook: 'onLeave'
                })
                .on("enter leave", function(e) {
                    if ( $("html, body").is(":animated") ) {
                      return;
                    }
                    if(e.type == 'enter'){
                      var $elem = triggerElem;
                    }else if(e.type == 'leave'){
                      var $elem = $(elem).closest('section').prev().attr('id');
                      console.log($elem);
                    }

                  if($elem != 'sub-nav'){
                      $rootScope.$broadcast('scrollspyTriggered', {
                          elemTarget: $elem
                      });
                  }
                })
                .addTo(controller);
          }

      };
}]);